import Offcanvas from 'bootstrap/js/dist/offcanvas'
import Tab from 'bootstrap/js/dist/tab'
import Modal from 'bootstrap/js/dist/modal'
import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/dist/dropdown'
import Toast from 'bootstrap/js/dist/toast'
import Tooltip from 'bootstrap/js/dist/tooltip'
import Swiper from 'swiper/bundle';

const topRight = document.querySelector(".top-header__right");
const topLeft = document.querySelector(".top-header__left");
const elementsRight = document.querySelectorAll(".top-header__menu-right");
const elementsLeft = document.querySelectorAll(".top-header__menu-left");
const topHeaderRight = document.querySelector(".top-header__offcanvas-right");
const topHeaderLeft = document.querySelector(".top-header__offcanvas-left");
const bottomHeaderRight = document.querySelector(".bottom-header__right-menu");
const headerOutside = document.querySelector(".bottom-header__outside");
const operationalContainer = document.querySelector(".top-header__offcanvas-operational")
const operationalElement = document.querySelector(".top-header__working-hour")
const offCanvasBody = document.getElementById("offcanvas-body")

// Search Side Content
const searchSideContent = document.getElementById("search-side-content")
const searchFilterContainer = document.getElementById("search-filter-container")
const searchFilterOffcanvas = document.getElementById("search-filter-offcanvas")

function offcanvas(arr, excludeClass, template) {
    Array.from(arr).forEach(el => {
        if (el.classList[0] !== excludeClass) {
            template.appendChild(el)
        }
    })
}

if (window.innerWidth < 992) {
    offcanvas(elementsRight, "top-header__language", topHeaderRight)
    offcanvas(elementsLeft, "top-header__working-hour", topHeaderLeft)
    operationalContainer.appendChild(operationalElement)
    headerOutside.appendChild(bottomHeaderRight)
    searchFilterOffcanvas.appendChild(searchSideContent)
}

// window.addEventListener("resize", () => {
//     if (window.innerWidth < 992) {
//         offcanvas(elementsRight, "top-header__language", topHeaderRight)
//         offcanvas(elementsLeft, "top-header__working-hour", topHeaderLeft)
//         operationalContainer.appendChild(operationalElement)
//         headerOutside.appendChild(bottomHeaderRight)
//         searchFilterOffcanvas.appendChild(searchSideContent)
//     } else {
//         // Right Menu
//         Array.from(elementsRight).forEach(el => {
//             topRight.appendChild(el)
//         })

//         Array.from(elementsLeft).forEach(el => {
//             topLeft.appendChild(el)
//         })
//         searchFilterContainer.appendChild(searchSideContent)
//         offCanvasBody.appendChild(bottomHeaderRight)
//     }
// })

// Login/Register Modal
const registerLink = document.querySelectorAll(".register-link");
const loginLink = document.querySelector(".login-link");
const registerForm = document.querySelector(".login__register-form");
const loginForm = document.querySelector(".login__login-form");
const loginButton = document.querySelector(".bottom-header__login");

const loginWNoBTN = document.querySelector('.loginWNo-btn');
const loginWIDBTN = document.querySelector('.loginWID-btn');

const loginWNoContainer = document.querySelector('.loginWNo');
const loginWIDContainer = document.querySelector('.loginWID');

loginWNoBTN.addEventListener('click', (e) => {
    e.preventDefault()
    loginWNoBTN.style.display = 'none'
    loginWNoContainer.style.display = 'block'
    loginWIDBTN.style.display = 'block'
    loginWIDContainer.style.display = 'none'
})

loginWIDBTN.addEventListener('click', (e) => {
    e.preventDefault()
    loginWNoBTN.style.display = 'block'
    loginWNoContainer.style.display = 'none'
    loginWIDBTN.style.display = 'none'
    loginWIDContainer.style.display = 'block'
})

loginButton.addEventListener('click', () => {
    loginForm.attributes.style.value = "display: block";
    registerForm.attributes.style.value = "display: none";
})

registerLink.forEach(el => {
    el.addEventListener('click', () => {
        loginForm.attributes.style.value = "display: none";
        registerForm.attributes.style.value = "display: block";
    })
})

loginLink.addEventListener('click', () => {
    loginForm.attributes.style.value = "display: block";
    registerForm.attributes.style.value = "display: none";
})

// Hide and show Password
const hideButton = document.querySelectorAll(".login__pass-btn");
const passInput = document.querySelectorAll(".login__pass-input");

hideButton.forEach((el, index) => {
    el.addEventListener("click", () => {
        const type = passInput[index].attributes.type;
        if (type.value == "password") {
            type.value = "text";
            el.innerHTML = '<iconify-icon icon="mdi:eye-off" width="24" height="24" class="change-password__icon"></iconify-icon>';
        } else {
            type.value = "password";
            el.innerHTML = '<iconify-icon icon="mdi:eye" width="24" height="24" class="change-password__icon"></iconify-icon>';
        }
    })
})

// Search Product List
const displayWidth = window.innerWidth;
const containerWidth = document.querySelector(".search__header")

let offsetWidth = ((displayWidth - containerWidth.offsetWidth) / 2) + 12
const specialPriceContainer = document.querySelector(".search-swiper__swiper-container")

if (displayWidth >= 992) {
    specialPriceContainer.classList.add("container")
} else {
    specialPriceContainer.classList.remove("container")
}

// window.addEventListener("resize", () => {
//     const displayWidth = window.innerWidth;
//     const containerWidth = document.querySelector(".navbar-container")
//     offsetWidth = ((displayWidth - containerWidth.offsetWidth) / 2) + 12

//     if (displayWidth >= 1024) {
//         specialPriceContainer.classList.add("container")
//     } else {
//         specialPriceContainer.classList.remove("container")
//     }
// })

const search_price_swiper = new Swiper('.search-swiper__swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1.5,
    spaceBetween: 20,
    slidesOffsetAfter: offsetWidth,
    slidesOffsetBefore: offsetWidth,
    coverflowEffect: {
        slideShadows: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.search-swiper__next-btn',
        prevEl: '.search-swiper__prev-btn',
    },

    // Breakpoints
    breakpoints: {
        575: {
            slidesPerView: 2.5,

        },
        768: {
            slidesPerView: 3.3,
            spaceBetween: 30,
        },
        991: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        992: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
        }
    }
})

// Search Show Top Header
const topHeader = document.getElementById("top-header") // Website top header
const searchTopHeader = document.querySelector(".search__top-header") // Element used to place Top Header when search open
const webTopHeader = document.getElementById("web-top-header") // Element used to place top header for website
const outsideHeader = document.querySelector(".bottom-header__outside") // Element used to place menu that not include into offcavas in mobile device
const searchMenuHeader = document.querySelector(".search__menu-header") // Element used to place menu into search offcanvas in mobile device

// Navbar Container
const navbarToglerContainer = document.querySelector(".navbar-toggler-container") // Navbar togler (Hamburger menu) container
const navbarToggler = document.querySelector(".navbar-toggler") // Navbar toggler (Hambuger menu) button
const navbarContainer = document.querySelector(".navbar-container") // Navbar menu

// Show and Close Search
const searchBtn = document.getElementById("searchBtn") // Search Button
const searchDisplay = document.querySelector(".search-close") // Close Button

// Search Offcanvas Container
const searchEl = document.getElementById("searchOffcanvas")
const searchHomeTab = document.getElementById("pills-home")

function searchMenu() {
    navbarToglerContainer.appendChild(navbarToggler)
    navbarContainer.insertBefore(outsideHeader, navbarContainer.children[1])
}

// Show
searchBtn.addEventListener('click', () => {
    if (searchEl.classList.contains("show")) {
        return
    }

    const bsOffcanvas = new Offcanvas(searchEl)
    searchTopHeader.appendChild(topHeader)
    if (displayWidth <= 991) {
        searchMenuHeader.appendChild(outsideHeader)
        searchMenuHeader.appendChild(navbarToggler)

        searchEl.addEventListener("hide.bs.offcanvas", () => {
            searchMenu()
        })
    }
    bsOffcanvas.show();
})

// Hide
searchDisplay.addEventListener('click', () => {
    const searchOffcanvas = Offcanvas.getInstance(searchEl);
    webTopHeader.appendChild(topHeader)
    if (displayWidth <= 991) {
        searchMenu()
    }
    searchOffcanvas.hide();
})

// Product Code 
// tooltipTriggerList
const producTitle = document.querySelectorAll(".product-title")
const searchProductTitle = document.querySelectorAll(".tab-product")

// Check to determine if an overflow is happening
function isOverflowing(element) {
    return element.scrollWidth > element.offsetWidth;
}

if (displayWidth > 991) {
    searchProductTitle.forEach(el => {
        if (isOverflowing(el)) {
            new Tooltip(el)
        }
    })

    producTitle.forEach(el => {
        if (isOverflowing(el)) {
            new Tooltip(el)
        }
    })
}

// Show Modal Function
function showModal(element) {
    const modal = new Modal(element)
    modal.show()
}

// Hide Modal Function
function hideModal(element) {
    const modal = new Modal(element)
    modal.hide()
}

// Wishlist Code Start
const toastTrigger = document.querySelectorAll('.liveToastBtn')
const productRemoveModal = document.getElementById('wishlistModal')
// const modal = new Modal(document.getElementById('wishlistModal'))

// Add Wishlist
if (toastTrigger) {
    const addWishlist = document.getElementById('addWishlist')
    const toastBootstrap = Toast.getOrCreateInstance(addWishlist)
    toastTrigger.forEach(item => {
        item.addEventListener('click', () => {
            // Show Success Add to Wishlist
            if (item.childNodes[1].getAttribute("src") == "assets/images/fav.svg") {
                const imgSrc = item.childNodes[1].setAttribute("src", "assets/images/fav-red.svg");
                toastBootstrap.show();
                return;
            }
            showModal(productRemoveModal)
        })
    })
}

// Remove Wishlist
document.getElementById("removeModal").addEventListener('click', () => {
    const removeWislist = document.getElementById('removeWislist')
    const toastBootstrap = Toast.getOrCreateInstance(removeWislist)
    toastTrigger.forEach(item => {
        const imgSrc = item.childNodes[1].setAttribute("src", "assets/images/fav.svg");
    })
    hideModal(productRemoveModal)
    toastBootstrap.show();
})

// Side Content Button
const viewMore = document.querySelectorAll(".side-content__search-view-more")

viewMore.forEach(el => {
    el.addEventListener("click", () => {
        const classList = Array.from(el.classList)
        // If the last class list is collpased the text change to "View More"
        if (classList[classList.length - 1] == "collapsed") {
            // [1] index for text
            el.children[0].innerText = "View More"
            // [2] index for up icon
            el.children[1].attributes.style.value = "display: none;";
            // [3] index for down icon
            el.children[2].attributes.style.value = "display: inline-block;";
        } else {
            // [1] index for text
            el.children[0].innerText = "Show Less"
            // [2] index for up icon
            el.children[1].attributes.style.value = "display: inline-block;";
            // [3] index for down icon
            el.children[2].attributes.style.value = "display: none;";
        }
    })
})


// Detail Modal Favorite
// Detail Add Wishlist
const addWishlistTrigger = document.querySelector(".product-detail__favorite");
const detailRemoveModal = document.getElementById("detailWishlistModal")

// Add Wishlist
if (addWishlistTrigger) {
    const addWishlist = document.getElementById('addWishlist')
    const toastBootstrap = Toast.getOrCreateInstance(addWishlist)
    addWishlistTrigger.addEventListener('click', (event) => {
        const heartIcon = document.querySelector(".product-detail__fav-icon")
        if (heartIcon.getAttribute("icon") == "mdi:heart-outline") {
            addWishlistTrigger.classList.add("product-detail__favorite-active")
            heartIcon.classList.add("product-detail__favorite-active")
            heartIcon.setAttribute("icon", "mdi:heart")
            toastBootstrap.show();
            event.preventDefault();
            return;
        }
        showModal(detailRemoveModal)
    })
}

//Remove Wishlist
if(document.getElementById("removeDetailWishlist")) {
    document.getElementById("removeDetailWishlist").addEventListener('click', () => {
        const removeWislist = document.getElementById('removeWislist')
        const toastBootstrap = Toast.getOrCreateInstance(removeWislist)
        const heartIcon = document.querySelector(".product-detail__fav-icon")
        addWishlistTrigger.classList.remove("product-detail__favorite-active")
        heartIcon.classList.remove("product-detail__favorite-active")
        heartIcon.setAttribute("icon", "mdi:heart-outline")
        toastBootstrap.show();
        hideModal(detailRemoveModal)
    })
}